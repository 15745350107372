import React from "react";
import {graphql, PageProps, navigate} from "gatsby";
import {Box, Grid, Typography} from "@mui/material";
import { verifySubscription } from "services/subscription";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import PCEmailSVG from "assets/images/pc_email.svg";

export default function VerifyPage({params}: PageProps) {
  const [isVerified, setVerified] = React.useState(false);

  const verify = React.useCallback(async (subscriptionId: string) => {
    try {
      await verifySubscription(subscriptionId);
      setVerified(true)
    } catch (e) {
      navigate("/", { replace: true })
    }
  }, [])

  React.useEffect(() => {
    verify(params.subscriptionId)
  }, [params])

  if (!isVerified) return null

  return (
    <SiteLayout>
      <Section padding={2}>
        <Box sx={{display: {xs: "none", md: "block"}, p: 10}}>
          <Grid container justifyContent="center">
            <Grid item container xs={12} md={6} alignContent="center">
              <Grid item md={12}>
                <Typography variant="h1" color="primary">Dziękujemy!</Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="h4">
                  Zostałeś zapisany do odbiorców newslettera DokDok!
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              alignContent="center"
              justifyContent="center"
            >
              <PCEmailSVG/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{display: {xs: "block", md: "none"}}}>
          <Grid container justifyContent="center">
              <Grid
                item
                container
                xs={12}
                md={6}
                alignContent="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item md={12} justifyContent="center">
                  <Typography variant="h1" align="center" color="primary">
                    Dziękujemy!
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography align="center">
                    Właśnie dołączyłeś do czytelników naszego newslettera!
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={6}
                alignContent="center"
                justifyContent="center"
                sx={{ mt: 6 }}
              >
                <PCEmailSVG height="200px" />
              </Grid>
          </Grid>
        </Box>
      </Section>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
